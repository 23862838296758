/*
 * Copyright 2017 General Code
 */

@keyframes hugeSize {
  from {
    clip: rect(1px, auto, auto, auto);
  }

  to {
    clip: rect(0px, auto, auto, auto);
  }
}

@keyframes largeSize {
  from {
    clip: rect(1px, auto, auto, auto);
  }

  to {
    clip: rect(0px, auto, auto, auto);
  }
}

@keyframes mediumSize {
  from {
    clip: rect(1px, auto, auto, auto);
  }

  to {
    clip: rect(0px, auto, auto, auto);
  }
}

@keyframes smallSize {
  from {
    clip: rect(1px, auto, auto, auto);
  }

  to {
    clip: rect(0px, auto, auto, auto);
  }
}

@media (min-width: 1240px) {
  #topArea {
    animation: hugeSize 0.001s 0s 1 linear alternate;
  }

  .responsive #content {
    font-size: 18px;
    width: 940px;
  }

  .responsive #menu, .responsive #top {
    left: 104px;
  }

  .responsive #footer {
    width: 940px;
    padding-left: 210px;
  }

  .responsive #main, .responsive #disclaimerContent {
    width: 1150px;
  }

  .responsive.widePage #main, .widePage.responsive #disclaimerContent {
    box-sizing: border-box;
    padding-left: 210px;
  }

  .responsive .sidebarArea {
    width: 210px;
  }

  .responsive #titleArea, .responsive #titleBox, .responsive #gps, .responsive #toolbar, .responsive #toolbarArea, .responsive .barTitle {
    width: 940px;
    box-sizing: border-box;
  }

  .responsive .subChild > div {
    width: 924px;
    box-sizing: border-box;
  }

  .responsive #gpsTitles {
    width: 820px;
  }

  .responsive #toc .titleLink {
    flex: 1;
    background-position: 913px 2px;
  }

  .responsive .section_content, .responsive .section-page, .responsive .notes, .responsive .questions {
    padding-left: 0;
    padding-right: 0;
  }

  .responsive #genericError, .responsive .modalError, .responsive .modalSmall {
    margin-left: auto;
    margin-right: auto;
  }

  .responsive .tableScrollWrapper > table {
    transform: scale(0.86);
  }

  .responsive .rightSidebarArea {
    display: block;
    /**
     * window width - content width == remaining free space
     * free space / 2 == space for each side
     * gutters 16px left + 8px right + 6px for the border + 10px because of wizards = 40px
     **/
    width: calc((100vw - 1150px) / 2 - 40px);
    left: 1166px;
  }

  .responsive .rightSidebarTabs.sticky {
    /**
     * window width - content width == remaining free space
     * free space / 2 == space for each side
     * gutters 16px left + 8px right + 6px for the border + 10px because of wizards = 40px
     **/
    width: calc((100vw - 1150px) / 2 - 40px);
    right: 16px;
  }
}

@media (min-height: 748px) {
  .responsive .rightSidebarTabs .sidebarTab .tabLabel .tabTitle {
    display: block;
  }
}

@media (min-width: 996px) and (max-width: 1239px) {
  #topArea {
    animation: largeSize 0.001s 0s 1 linear alternate;
  }

  body.responsive {
    min-width: 1013px;
  }

  .responsive #toc .mediumTitle ~ .barTitle {
    width: auto;
  }

  .responsive #googleTranslateWidget {
    min-width: 168px;
  }

  #createQuestion .menuText {
    display: none;
  }

  .responsive .rightSidebarArea {
    display: block;
    /**
     * window width - content width == remaining free space
     * free space / 2 == space for each side
     * gutters 16px left + 8px right + 6px for the border + 10px because of wizards = 40px
     **/
    width: calc((100vw - 940px) / 2 - 40px);
    left: 956px;
  }

  .responsive .rightSidebarTabs.sticky {
    /**
     * window width - content width == remaining free space
     * free space / 2 == space for each side
     * gutters 16px left + 8px right + 6px for the border + 10px because of wizards = 40px
     **/
    width: calc((100vw - 940px) / 2 - 40px);
    right: 16px;
  }

  .responsive #userSelectionLabels .userCheckboxSpacer {
    width: 500px;
    flex: 0 1 500px;
  }
}

@media (min-width: 760px) and (max-width: 995px) {
  #topArea {
    animation: mediumSize 0.001s 0s 1 linear alternate;
  }

  #responsiveView {
    display: inline-block;
  }

  .responsive #responsiveView {
    display: none;
  }

  .responsive #desktopView {
    display: inline-block;
  }

  body.responsive {
    min-width: 782px;
  }

  .responsive div#top {
    width: 700px;
    height: 79px;
    background-size: 700px 79px;
  }

  .responsive div#muniName {
    margin-top: 16px;
  }

  .responsive #topButtons {
    display: none;
  }

  .responsive #menu {
    width: 700px;
  }

  .responsive #adminButton, .responsive #multicodeSearchButton {
    display: none;
  }

  .responsive #homeButton {
    margin-left: 88px;
  }

  .responsive #helpButton {
    border-right: none;
  }

  .responsive #content {
    float: none;
    margin-left: auto;
    margin-right: auto;
  }

  .responsive #menubar #menuSearchArea {
    display: block;
  }

  .responsive.expandedMenu #helpButton {
    left: 175px;
  }

  .responsive #menubarExpand {
    display: inline;
    color: #fff;
    background-color: transparent;
    position: absolute;
    left: 0;
    top: 0;
    height: 30px;
    padding-top: 10px;
    cursor: pointer;
    border-left: 1px solid #444;
    border-right: 1px solid #222;
  }

  .responsive .sidebarArea {
    margin-top: 0;
    width: 300px;
    overflow: hidden;
    max-height: 0;
    transition: height 0.2s, max-height 0.2s;
    position: absolute;
    z-index: 13;
    background-color: #fbfbfb;
    top: 133px;
    box-sizing: border-box;
  }

  .responsive.expandedMenu .sidebarArea {
    max-height: 4000px;
    border: 1px solid #666;
  }

  .responsive .sidebarGroup {
    width: 100%;
    position: static !important;
    border-left: none;
  }

  .responsive .sidebar {
    width: 100%;
  }

  .responsive #menubarExpand:hover #menubarExpandLabel {
    opacity: 1;
  }

  .responsive #menubarExpandLabel:after {
    content: "Menu";
  }

  .responsive #menubarExpandLabel {
    opacity: 0.4;
    transition: opacity 0.2s;
    font-size: 16px;
    font-weight: 500;
    text-shadow: 0 -1px 0 rgba(0, 1, 1, 0.6);
    padding-left: 30px;
    margin-left: 10px;
    padding-right: 10px;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 70 60'%3E%3Cpath d='M5 12h60c2.8 0 5-2.7 5-6s-2.2-6-5-6H5C2.2 0 0 2.7 0 6s2.2 6 5 6zm60 12H5c-2.8 0-5 2.7-5 6s2.2 6 5 6h60c2.8 0 5-2.7 5-6s-2.2-6-5-6zm0 24H5c-2.8 0-5 2.7-5 6s2.2 6 5 6h60c2.8 0 5-2.7 5-6s-2.2-6-5-6z' fill='%23fbfbfb'/%3E%3C/svg%3E");
    background-position: left 0;
    background-size: 20px 22px;
  }

  .responsive .facetListContainer, .responsive .resultSortContainer  {
    display: none;
  }

  .responsive #toolbarArea {
    display: none;
  }

  .responsive#searchResultsPage #toolbarArea {
    display: block;
  }

  .responsive #toolbar {
    width: 698px;
  }

  .responsive #main, .responsive #disclaimer #disclaimerContent {
    width: 700px;
    position: relative;
  }

  .responsive #disclaimer #disclaimerContent {
    display: inline-block;
  }

  .responsive #disclaimer #disclaimerContent > .tos, .responsive #disclaimer #disclaimerContent > #googleTranslateWidget {
    display: inline-block;
  }

  .responsive .selectionBox {
    display: none;
  }

  .responsive .titleNumber, .responsive .titleLink {
    padding-left: 0;
  }

  .responsive #footer {
    width: 700px;
  }

  .responsive#searchResultsPage #content {
    width: 700px;
  }

  .responsive #resultRequeryInput {
    padding-right: 58px;
  }

  .responsive #resultRequerySubmitContainer {
    right: 0;
  }

  .responsive #resultTitle {
    width: 700px;
  }

  .responsive #resultList {
    margin-top: 24px;
  }

  .responsive .resultInfo {
    display: none;
  }

  .responsive .resultDivider {
    display: none;
  }

  .responsive .result {
    width: 698px;
  }

  .responsive#loginPage .content, #settingsPage .content {
    width: 100%;
  }

  .responsive#loginPage #loginExisting {
    padding-left: 40px;
    padding-right: 30px;
    height: 280px;
  }

  .responsive#loginPage #loginButton {
    margin: 8px auto auto auto;
    float: none;
    display: block;
    width: 50%;
  }

  .responsive#loginPage #usernameArea, .responsive#loginPage #passwordArea {
    width: 100%;
    float: none;
    margin-left: 0;
  }

  .responsive#loginPage #content, #settingsPage #content {
    width: 100%;
  }

  .responsive .requestBlock {
    width: 500px;
  }

  .responsive .sidebarArea {
    top: -30px;
  }

  .responsive .sidebarArea .dropMenuOnly {
    display: block;
  }

  .responsive #overlay, .responsive .modal {
    display: none !important;
  }

  .responsive .attachmentsModal.modal {
    display: block !important;
    width: 400px;
  }

  .responsive #toc .mediumTitle ~ .barTitle {
    width: auto;
    margin-left: 0;
  }

  .responsive #googleTranslateWidget {
    min-width: 168px;
  }

  .responsive #userSelectionLabels .userCheckboxSpacer {
    width: 500px;
    flex: 0 1 500px;
  }
}

@media (max-width: 759px) {
  #topArea {
    animation: smallSize 0.001s 0s 1 linear alternate;
  }

  #responsiveView {
    display: inline-block;
  }

  .responsive #responsiveView {
    display: none;
  }

  .responsive #desktopView {
    display: inline-block;
  }

  .responsive #topArea {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 31px;
    z-index: 10;
  }

  html .responsive #top {
    width: 100%;
    height: 30px;
    background-image: none;
    background-size: 100% 100%;
  }

  .responsive #topArea:before {
    display: none;
  }

  .responsive #topButtons {
    display: none;
  }

  html .responsive #muniName {
    display: block;
    margin-top: 0;
    font-size: 20px;
    line-height: 30px;
  }

  .responsive.open #main {
    margin-left: auto;
  }

  .responsive #content {
    margin-top: 0;
  }

  .responsive #toc {
    margin-top: 8px;
  }

  .responsive #drawerContainer {
    display: none;
  }

  .responsive #menubar {
    height: 40px;
    transition: height 0.2s;
    overflow: visible;
    position: fixed;
    z-index: 1;
  }

  .responsive #menu {
    width: 100%;
    border-left: none;
  }

  .responsive #menubar .menuButton {
    display: none;
  }

  .responsive #menubar #menuSearchArea {
    display: block;
    padding-right: 16px;
  }

  .responsive.expandedMenu #menubar {
    height: 40px;
  }

  .responsive.expandedMenu #helpButton {
    left: 40%;
  }

  .responsive.expandedMenu #homeButton, .responsive.expandedMenu #helpButton {
    position: absolute;
    display: flex;
    top: 41px;
    width: 40%;
    box-sizing: border-box;
    border-top: 1px solid #666;
    height: 40px;
    border-bottom: 1px solid #222;
    padding-top: 8px;
    padding-bottom: 8px;
    background-color: #333;
    z-index: 13;
  }

  .responsive .menubarSearchButtonWrapper, .responsive .facetListContainer, .responsive .resultSortContainer {
    display: none;
  }

  .responsive #menubarContainer {
    z-index: 1;
  }

  .responsive #menubarContainer {
    position: fixed !important;
    width: 100% !important;
    z-index: 13;
  }

  .responsive .sidebarArea {
    margin-top: 0;
    width: 80%;
    overflow: hidden;
    max-height: 0;
    position: fixed;
    z-index: 13;
    background-color: #fbfbfb;
    top: 122px;
    box-sizing: border-box;
    margin-left: -8px;
  }

  .responsive.expandedMenu .sidebarArea {
    max-height: 4000px;
    border: 1px solid #333;
    border-left: none;
  }

  .responsive .sidebarGroup {
    width: 100%;
    position: static !important;
  }

  .responsive .sidebar {
    width: 100%;
  }

  .responsive #menubarSearchField {
    font-weight: 600;
    font-size: 20px;
  }

  .responsive #autoSuggestContainer {
    width: 98%;
  }

  .responsive #menubarExpand {
    cursor: pointer;
    display: inline;
    font-size: 24px;
    color: #fff;
    height: 40px;
    line-height: 40px;
    float: left;
  }

  .responsive #menubarExpandLabel {
    padding-left: 20px;
    padding-right: 20px;
    width: 19px;
    height: 14px;
    background-repeat: no-repeat;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAAOCAQAAABnEe8CAAAATElEQVQoz62RMQEAIQwDIwUpLwFJdYIEJFQKEhBwQ3HwZCC33tAmYlBXUoZUlAhDC70NjbjSxDJuW2Ib2hYfk/xl0l9/6tXrjpXO9Ae1t2Ql7DezBQAAAABJRU5ErkJggg==);
    background-position: center;
  }

  .responsive #toolbarArea, .responsive #gpsPrev, .responsive #gpsNext {
    display: none;
  }

  .responsive#searchResultsPage #toolbarArea {
    display: block;
  }

  .responsive #filterContainerContainer {
    max-width: none;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    width: 100%;
  }

  .responsive #filterContainerContainer {
    height: 44px;
  }

  .responsive #sortDrop {
    display: none;
  }

  .responsive .toolbarFill {
    display: none;
  }

  .responsive #toolbar {
    width: 100%;
    border-left: 0;
    border-right: 0;
  }

  .responsive .selectionBox {
    display: none;
  }

  .responsive .titleNumber, .responsive .titleLink {
    padding-left: 0;
  }

  .responsive #main, .responsive #content, .responsive #footer, .responsive #titleArea {
    width: 100%;
  }

  .responsive #main, .responsive #disclaimer #disclaimerContent {
    width: auto;
    box-sizing: border-box;
  }

  .responsive #main {
    padding: 0 8px 0 8px;
  }

  .responsive #disclaimer #disclaimerContent {
    display: inline-block;
  }

  .responsive #disclaimer #disclaimerContent > .tos, .responsive #disclaimer #disclaimerContent > #googleTranslateWidget {
    display: inline-block;
  }

  .responsive #gps, .responsive #titleArea {
    left: 0;
    width: 100%;
    box-sizing: border-box;
  }

  .responsive #gps.stickyGps {
    padding-left: 4px;
    padding-right: 4px;
  }

  .responsive #searchContextInfo {
    /* 3em seems to be the actual width of the left button and 4px is the visual space we want */
    margin-left: calc(3em + 4px);
    /* 2.5em is just right for next result and padding */
    margin-right: 2.5em;
    /* needs this to get 8px below the whole element */
    margin-bottom: 1px;
  }

  .responsive #codePageTitleArea:first-child #searchContextInfo {
    margin-left: 0;
  }

  .responsive #codePageTitleArea:last-child #searchContextInfo {
    margin-right: 0;
  }

  .responsive #titleBox {
    width: 100%;
    height: auto;
  }

  .responsive .resultContextNav {
    position: absolute;
    margin: 0;
    bottom: 4px;
    font-size: 0.75rem;
  }

  .responsive .resultContextNav:first-child {
    left: 4px;
  }

  .responsive .resultContextNav:last-child {
    right: 4px;
  }

  .responsive #searchContextControls {
    display: block;
    margin-top: -4px;
  }

  .responsive #gps #codePageTitleArea {
    padding-top: 8px;
    padding-bottom: 4px;
  }
  .responsive #gps.stickyGps #codePageTitleArea {
    padding-top: 4px;
  }

  .responsive #gps.stickyGps #codePageTitleArea #pageTitle {
    line-height: 18px;
  }

  .responsive #pageTitle .titleNumber {
    float: none;
  }
  .responsive #pageTitle .titleTitle {
    display: inline;
    overflow: visible;
    text-overflow: ellipsis;
  }

  .responsive #titleArea .titleNumber, .responsive #titleArea .titleTitle {
    font-size: 18px;
  }

  .responsive #titleArea .titleLink {
    padding-top: 5px;
    padding-left: 4px;
    font-size: 14px;
  }

  .responsive .breadCrumbs {
    font-size: 0.75rem;
  }

  .responsive #toc .titleChevron {
    display: none;
  }

  .responsive #toc .barTitle .titleLink, .responsive .barTitle .titleLink {
    width: 100%;
    box-sizing: border-box;
    background-position: 98%;
  }

  .responsive #toc .mediumTitle ~ .barTitle {
    width: auto;
    margin-left: 0;
  }

  .responsive .bigOnly {
    display: none;
  }

  .responsive #gcLogo, .responsive #purchase {
    display: none;
  }

  .responsive #poweredBy {
    display: block;
    position: static;
  }

  .responsive #footerArea {
    min-height: 88px;
    padding-top: 16px;
  }

  .responsive #appLinks {
    position: static;
    margin-top: 8px;
    right: auto;
    top: auto;
  }

  .responsive input[type="text"] {
    -webkit-appearance: none;
    border-radius: 0;
  }

  .responsive #indexLettersArea {
    margin-top: 0;
  }

  .responsive #indexLetters {
    height: auto;
    width: auto;
    display: block;
    text-align: center;
  }

  .responsive .letter {
    padding: 7px;
    font-size: 20px;
  }

  .responsive .entryReferences {
    margin-left: 0;
  }

  .responsive #notePage ul {
    margin-left: 0;
    padding-left: 0;
  }

  .responsive #docHead {
    margin-top: 8px;
    border-bottom: none;
  }

  .responsive #docButtonArea {
    display: none;
  }

  .responsive #docShowingArea {
    display: none;
  }

  .responsive #docFlowArea {
    margin-top: 8px;
  }

  .responsive .docFlowSubArea {
    float: none;
    width: 100%;
  }

  .responsive .docFlowSubArea:first-child .docFlowList, .responsive .docFlowList {
    width: 100%;
    margin-left: 0;
    line-height: 1.5;
    height: 39px;
    font-size: 18px;
  }

  .responsive .docCategoryEditButton, .responsive .docEditLink {
    display: none;
  }

  .responsive #totalResultCount {
    display: none;
  }

  .responsive #resultTitle {
    width: 100%;
  }

  .responsive #resultRequerySubmitContainer {
    right: 0;
    top: -70px;
    width: 30px;
    height: 40px;
    opacity: 0;
    overflow: hidden;
  }

  .responsive #resultTitle {
    margin-top: 0;
    margin-bottom: 29px;
  }

  .responsive .result {
    width: 100%;
    box-sizing: border-box;
  }

  .responsive .resultContent {
    width: 100%;
    box-sizing: border-box;
  }

  .responsive .resultDivider, .responsive .resultInfo {
    display: none;
  }

  .responsive .result:hover .resultToolsContainer {
    visibility: hidden;
    opacity: 0;
  }

  .responsive .resultLine {
    display: none;
  }

  .responsive .resultShowMore {
    width: auto;
    right: auto;
    left: -8px;
  }

  .responsive .showTriangleShadow {
    display: inline-block;
    margin-right: 0;
    float: none;
    margin-left: 4px;
    top: -1px;
  }

  .responsive #resultRequeryInput {
    background-color: #454545;
    color: white;
    height: 40px;
    line-height: 43px;
    font-size: 20px;
    text-shadow: none;
    padding-left: 9px;
    box-shadow: inset 0 1px 1px #383838;
  }

  .responsive #resultRequeryInputContainer {
    position: absolute;
    top: -70px;
    left: 50px;
    right: 33px;
  }

  .responsive#helpPage .content img {
    max-width: 100%;
    box-sizing: border-box;
  }

  .responsive .filterButton:hover .filterCountContainer {
    display: none;
  }

  .responsive .codeTable, .responsive .table {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .responsive .codeTable table {
    table-layout: auto;
  }

  .responsive#errorPage .content {
    padding-left: 10px;
    padding-right: 10px;
  }

  .responsive#loginPage .content , #settingsPage #content {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }

  .responsive#loginPage #loginExisting {
    padding-left: 40px;
    padding-right: 30px;
    margin-right: 20px;
    height: 280px;
  }

  .responsive .requestBlock {
    float: none;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }

  .responsive .genericLoginButton {
    margin: 0;
  }

  .responsive .genericLoginButtonBlock {
    float: none;
    width: 100%;
    text-align: center;
    margin-top: 32px;
  }

  .responsive#loginPage #usernameArea, .responsive#loginPage #passwordArea {
    width: 100%;
    float: none;
    margin-left: 0;
  }

  .responsive#loginPage #loginButton, .changeSubmit {
    margin: 8px auto auto auto;
    float: none;
    display: block;
    width: 50%;
  }

  .responsive .changeSubmit {
    float: right;
    margin: 32px 60px 0 0;
  }

  .responsive#loginPage #content {
    width: 100%;
  }

  .responsive #warning {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .responsive #noheading {
    margin-top: -72px;
  }

  .responsive .litem table, .responsive .chapter table, .responsive .article table, .responsive .subarticle table, .responsive .section table {
    margin: 0;
  }

  .responsive .sidebarArea .dropMenuOnly {
    display: block;
  }

  .responsive #overlay, .responsive .modal {
    display: none !important;
  }

  .responsive .attachmentsModal.modal {
    display: block !important;
    width: 400px;
  }

  .responsive .responsiveTable,
  .responsive .responsiveTable table {
    box-shadow: none;
  }

  .responsive .responsiveTable thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .responsive .responsiveTable tr:not(.tableDetailRow) {
    border-top: 1px solid #DDD;
  }

  .responsive .responsiveTable tr:not(.tableDetailRow) td:first-child {
    margin-top: 8px;
  }

  .responsive .responsiveTable tr.tableDetailRow td:first-child {
    margin-top: -8px;
  }

  .responsive .responsiveTable tr td:last-child {
    margin-bottom: 8px;
  }

  .responsive .responsiveTable td {
    border: none;
    display: block;
  }

  .responsive .responsiveTable td:before {
    padding-right: 8px;
  }

  .responsive .responsiveTable td[data-column-name]:before {
    font-weight: bold;
    content: attr(data-column-name) ": ";
    float: left;
  }

  .responsive .responsiveTable tr.tableDetailRow td:before {
    font-weight: bold;
    content: "Comment: ";
    float: left;
    width: 100%;
  }

  .responsive .responsiveTable tr.tableDetailRow td {
    padding: 4px 8px 4px 16px!important;
  }

  .responsive #googleTranslateWidget {
    min-width: 168px;
  }

  .responsive .goog-te-banner-frame {
    display: none;
  }

  .responsive #userSelectionLabels .userSelectionLabel span {
    display: none;
  }
}

.responsive.translate #footerArea {
  padding-bottom: 80px;
}

.js .note-loading[data-start-open="false"] .noteContent {
  display: none;
}
